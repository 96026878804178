.after-content{
    section.content-image{
        display: flex;
        padding-top: 2rem;
        padding-bottom: 4rem;
        gap:0 2rem;
        .block-icon{
            position: relative;

            img{
                width: 100%;
                height: 100%!important;
                object-fit: contain;

            }
        
        }
        .block-content{
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 540px;
        }
        &:nth-of-type(odd){
            flex-direction: row-reverse;
        }
        &:nth-of-type(3n+1){
            .block-title{
                color: var(--yellow);
            }
           
            .btn-outline{
                border-color: var(--yellow);
                color: var(--yellow);
                &:hover{
                    color: var(--white);
                    background-color: var(--yellow);
                }
            }
        }
        &:nth-of-type(3n+2){
            .block-title{
                color: var(--blue);
            }
            .btn-outline{
                border-color: var(--blue);
                color: var(--blue);
                &:hover{
                    color: var(--white);
                    background-color: var(--blue);
                }
            }
        }
        &:nth-of-type(3n+3){
            .block-title{
                color: var(--pink);
            }
            .btn-outline{
                border-color: var(--pink);
                color: var(--pink);
                &:hover{
                    color: var(--white);
                    background-color: var(--pink);
                }
            }
        }
       
        @media screen and (max-width:768px) {

            flex-direction: column!important;
            .block-content{
                margin-top:0;
                padding-top:0;
                max-width: none;
                justify-content: center;
                text-align: center;
            }
        }
    }
    div.steps-block{
        background: var(--pink-gradient);
        padding-top: 5rem;
        padding-bottom: 5rem;
        .block-title{
            text-align: center;
            margin-bottom: 6rem;
            color:var(--white);
        }
        .btn-solid{
            color: var(--pink);
        }
        .step-list{
            margin:0;
            padding:0 2rem;
            width: 100%;
            list-style: none;
            display: flex;
            justify-content: space-around;
            color: var(--white);
            text-align: center;

            li{
                width: clamp(150px, 15vw, 200px);
                &.line:nth-child(even){
                    width: 50px;
                    height: 200px;
                    z-index: 10;
                    transform: scale(2);
                }
                &.line:nth-child(6n+2){
                    content: url('../assets/line-1.svg');
                }
                &.line:nth-child(6n+4){
                    content: url('../assets/line-2.svg');
                }
                &.line:nth-child(6n+6){
                    content: url('../assets/line-3.svg');
                }
            }
            .list-ctr{
                margin-top: -2rem;
                width:80px;
                height: 80px;
                color:var(--black);
                border-radius: 50%;
                border: 8px solid #C8C8C8;
                background-color: var(--white);
                font-size: clamp(20px, 3.5vw, 42px);
                font-weight: 900;
            }
            .step-icon{
                margin:auto;
                // width: 20vw;
                aspect-ratio: 1/1;
                border:9px solid var(--white);
                border-radius: 50%;
                background-color: rgba(255,255,255,.2);
                transition: background-color .15s ease-in-out;
             
                &:hover{
                    background-color: var(--blue);
                }
                img{
                    width: 100%;
                    height: 100%!important;
                    object-fit: contain;
                    transform: translateY(-20px);
                }
                
            }

            .step-title{
                text-align: center;
                font-weight:500;
                font-size: clamp(18px, 3.25vw, 30px);
            }
        }
        @media screen and (max-width:1200px) {
            .step-list{
                // flex-direction: column;
                flex-wrap: wrap;
                gap:2rem;
                .line{
                    display: none;
                }
            }
        }
    }
    div.staff-block{
        padding: 3rem 0;
        .block-title{
            color: var(--blue);
            text-align: center;
            margin-bottom: 3rem;
        }
        .staff-list{
            padding:0;
            margin:0;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            gap:2rem;
            justify-content: center;
            .staff-card{
                max-width:400px;
                text-align: center;
                a{
                    text-decoration: none;
                    color:inherit;
                }
            }
            .staff-icon{
                padding:2rem;
                img{
                    width: 100%;
                    height: 100%!important;
                    object-fit: contain;
                    border-radius: 50%;
                }
            }
            .staff-title{
                color:var(--blue);
                font-size: clamp(18px, 3.5vw, 24px);
                font-weight: 700;
                margin:0;
            }
            .staff-position{
                color:var(--black);
                font-size: 18px;
                font-weight: 400;
                margin:0;
                min-height: 24px;
            }
            hr{
                width:90%;
                margin:2.5rem auto;
                border-color: var(--blue);
            }
            .summary{
                margin-bottom: 3rem;
            }
            a.btn-outline-blue{
                width: 260px;
            }
        
        }
        
    }
    div.content-modal{
        color: var(--white);
        text-align: center;
        background-color: var(--pink);
        max-width: 1088px;
        width: 100%;
        padding:1rem 4rem;
        transform: translateY(50%);
        z-index: 10;
        padding:2rem;
        .block-title{
            margin:0 auto;
            max-width: 800px;
        }
        
    }
}