
/*============ Contact ============*/
.contact-details.has-map,
.map-wrap {
    display: inline-block;
    vertical-align: bottom;
    width: 48%;
    box-sizing: border-box;
}
.contact-details.has-map {
    padding-right: 10px;
}

.contact-details p {
    margin: 5px 0;
}
.opening-hours {
    display: block;
}
.contact-wrapper form {
    padding-top: 20px;
}
.contact-wrapper form p {
    margin: 10px 0;
}


.kms-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
}
.t-contact{
    .splash{
        .btn-solid{
            color:var(--blue);
            font-weight: 700; 
        }
    }
    .page-content{
        margin:0;
        padding:0;
    }
    .page-body{
        padding-bottom: 0;
    }
    .form-wrapper{
        margin-top: 0;
        padding-top:0;
    }
}