.t-stories{
    .splash{
        .btn-solid{
            color:var(--pink);
            font-weight: 700; 
        }
    }
    .back-btn{
        margin-top: 1rem;
        
    }
    .page-content{
        margin:0;
        padding:0;
    }
    .page-body{
        padding-bottom: 0;
    }
    .story-wrapper{
        padding-top:0;
        padding-bottom:0;
        .highlight{
            .card-icon{
                img{
                    aspect-ratio: 16/9;
                }
            }
            .card-title{
                color: var(--pink);
                margin-top: 4rem;
            }
            margin-bottom:4rem;
            padding-bottom: 2rem;
            border-bottom: 3px solid var(--blue);
        }
        
    }
    .card-list{

        margin-bottom:4rem;
        grid-gap: 1.5rem;
        .card{
            a{
                display: flex;
                flex-direction: column;
                height: 100%;
            }
            .card-icon{
                img{
                    aspect-ratio: 1/1;
                }
            }
            .card-content-wrapper{
                height: 100%;
                display: flex;
                flex-direction: column;
                .card-link{
                    margin-top:auto;
                    padding-top: 1rem;
                    button{
                        font-weight: 700;
                        font-size: clamp(16px, 3vw, 21px);
                    }
                }
            }
        }
        @media screen and (max-width:768px) {
            grid-gap: 5rem 1.5rem;
            text-align: center;
        }
    }
    .news-highlight{
        margin-top:3rem;
        margin-bottom:8rem;
        grid-gap:2rem;
        .news-title{
            color: var(--pink);
        }
        @media screen and (max-width:768px) {
            grid-template-columns: 1fr;
            grid-gap:1rem;
            text-align: center;
            .news-title{
                margin-top: 2rem;
            }
        }
    }
}