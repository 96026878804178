
.t-news{
    .splash{
        .btn-solid{
            color:var(--blue);
            font-weight: 700; 
        }
    }
    .back-btn{
        margin-top: 2rem;
    }
    .page-content{
        margin:0;
        padding:0;
    }
    .page-body{
        padding-bottom: 0;
    }
    .highlight{
        padding-bottom: 5rem;
        border-bottom: 3px solid var(--blue);
        .card{
            a{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap:6rem;
                @media screen and (max-width:768px) {
                    grid-template-columns: 1fr;
                    grid-gap:1rem;
                    text-align: center;
                }
                
            }
            .card-icon{
                display:flex;
                justify-content: center;
                align-items: center;
                img{
                    aspect-ratio: 1/1;
                }
            }
            .card-content-wrapper{
                display: flex;
                flex-direction: column;
                .card-date{
                    order:-1;
                    color: var(--blue);
                    font-weight: bold;
                    font-size: clamp(18px, 3vw, 35px);
                }
                .card-title{
                    color: var(--pink);
                    margin-top:1rem;
                    font-size: clamp(20px, 3.5vw, 44px);
                }
                .card-link{
                    margin-top:2rem;
                }
            }
        }
    }
    .news-wrapper{
        padding-top: 0;
    }
    .card-list{
        margin-top:5rem;
        margin-bottom:5rem;
        grid-gap: 10rem 1.5rem;
        .card{
            a{
                display: flex;
                flex-direction: column;
                height: 100%;
            }
           
            .card-content-wrapper{
                height: 100%;
                display: flex;
                flex-direction: column;
                .card-link{
                    margin-top:auto;
                    padding-top: 1rem;
                    text-align: center;
                    button{
                        font-weight: 700;
                        font-size: clamp(16px, 3vw, 21px);
                    }
                }
            }
        }
        @media screen and (max-width:500px) {
            grid-gap: 5rem 1.5rem;
            text-align: center;
        }
    }
    .news-highlight{
        margin-top:4rem;
        padding-bottom: 2rem;
        grid-gap:2rem;
        .news-icon{
            margin:auto;
            img{
                width:100%;
                height:100%!important;
                object-fit: contain;
            }
        }
        .news-content{
            display: flex;
            flex-direction: column;
            justify-content: center;
            .news-title{
                color: var(--pink);
                margin-top: 0;
            }
        }
        @media screen and (max-width:768px) {
            grid-template-columns: 1fr;
            grid-gap:1rem;
            text-align: center;
            .news-title{
                margin-top: 3rem;
            }
        }
    }
}