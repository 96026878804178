html {
    color: #222;
    font-size: 1em;
    line-height: 1.4;
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 3px solid var(--blue);
    margin: 1em 0;
    padding: 0;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}
input,
select,
textarea,
button {
    border-radius:0; /* reset safari round corners */
}


/* ==========================================================================
   Author's custom styles
   ========================================================================== */

body {
    padding:0;
    margin:0;
    font-size:clamp(16px, 3vw, 22px);
}

.contentwrap {
    max-width:1364px;
    margin: 0 auto;
    position:relative;
    padding: 2rem;
    @media screen and  (max-width:500px){
        padding: 1rem; 
    }
}
header .contentwrap,
footer .contentwrap {
    padding: 0 20px;
}

img {
    border:none;
}
a.logo {
    display: inline-block;
    width: 250px;
    height:auto;
}
header a,
footer a,
.contact-details a {
    text-decoration: none;
    color: inherit;
}
.pager{
    margin:4rem 2rem;
    display: flex;
    gap:1rem;
    flex-wrap: wrap;
    justify-content: center;
    .button{
        background-color: var(--pink);
        border: none;
        transition: background-color .3s ease-in-out;
        &:hover{
            background-color: var(--blue);
            color:var(--white)
        }
        &.active{
            background-color: var(--blue);
            cursor: pointer;
            pointer-events: none;
        }
        
    }
}
.card{
    a{ 
        height: 100%;
        text-decoration: none;
        &:hover{
            .card-title, .card-date{
                color: var(--pink);
            }
            .card-link{
                button{
                    background-color: var(--blue);
                    color: var(--white);
                }
            }
        }
    }
    .card-icon{
        img{
            width:100%;
            aspect-ratio: 16/10;
            object-fit: cover;
        }
    }
    .card-title{
        color: var(--blue);
        font-weight: 700;
        font-size: clamp(17px, 3.5vw, 24px);
        margin-top: 1.5rem;
        margin-bottom: 1rem;
    }
    .card-content{
        font-size: clamp(16px, 3vw, 22px);
        color:var(--black);
    }
    .card-date{
        font-size: clamp(16px, 3vw, 18px);
        color:var(--blue);
        font-weight: 700;
        margin:1rem 0;
    }
    .card-link{
        font-size: clamp(16px, 3vw, 22px);
        a,button{
            font-weight: 700;
        }
    }
}
@import "block";
@import "menus";
@import "forms";
@import "site_links";
@import "header";
@import "mobile_menu";
@import "footer";
@import "index";
@import "page";
@import "contact";
@import "stories";
@import "news";

/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (max-width: 760px) {

    a {

        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;

        -ms-word-break: break-all;
        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;

        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;

    }
/** form **/
    .contact-details.has-map,
    .map-wrap{
        width: 100%;
        padding: 0px;
    }
    .map-wrap {
        margin-top: 20px;
        height: 80vh;
    }
    #map-click {
        display: block;
    }
}
@import "helpers";
@import "print";