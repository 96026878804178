/** site links **/
.site-link {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 5px;
    transition: transform .3s ease-in-out;
    &:hover{
        filter:brightness(0) saturate(100%) invert(30%) sepia(52%) saturate(2245%) hue-rotate(308deg) brightness(98%) contrast(99%);
        transform: scale(1.2);
    }
}