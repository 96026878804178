/*============ header ============*/
header.top {
    font-family: var(--font-primary);
    font-size:20px;
    padding: 1rem clamp(10px, 5vw, 5rem);
    display: flex;
    align-items: center;
    a.logo{
        width: 180px;
    }
}
.header-bar-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.header-bar-top {
    color:var(--grey);
    background: var(--white);
    line-height: 26px;
    font-weight: 700;
}


header.top li {
    vertical-align:top;
    a{
        border-bottom: 1px solid transparent;
        &:hover{
            border-color:var(--blue);
            color:var(--blue);
        }
    }
    &.active{
        & > a{
            border-bottom: 1px solid var(--blue);
            color:var(--blue);
  
        }
    }
}
.header-menu {

}
.header-menu > ul {
    text-align:right;
}
.header-menu li {
    margin:20px 10px;
}
.header-bar-bottom {
   
    color:var(--pink);
    font-weight: 700;
    position:relative;
    .main-menu{
        a{
            transition: all .2s ease-in-out,;
            border-bottom: 1px solid transparent;
            padding-bottom: 0;
            &:hover{
                border-color:var(--blue);
                color:var(--blue);
            }
        }
    }
    
 
}
.main-menu ul {
    text-align:justify;
    line-height:0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    row-gap:1rem;
}
.main-menu ul::after {
    content:"";
    display:inline-block;
    width:100%;
    line-height:0px;
    vertical-align:top;
}
.main-menu li {
    margin:5px 1rem;
    line-height:1;
}
.main-menu li.depth-0 {
    vertical-align:middle;
    &:last-child{
        background-color: var(--blue);
        color:var(--white)!important;
        border-radius: 62px;
        padding: 10px 2rem;
        transition: background-color .3s ease-in-out;
        text-align: center;
        white-space: nowrap;

        a{
            color:var(--white)!important;
            padding:0;
            border: none;
        }
        &:hover{
            background-color: var(--pink);
            a{
                color: var(--white);
                
            }
        }
    }
}


.main-menu li > ul {
    position:absolute;
    top: 100%;
    left:0;
    right:0;
    background:#f6f6f6;
    color:#082133;
    z-index:10000;
    text-align: left;
    display:none;
}
.main-menu li:hover > ul {
    display:block;
}
.main-menu li > ul::before,
.main-menu li > ul::after {
    content:"";
    background:#f6f6f6;
    position:absolute;
    top:0;
    bottom:0;
    width:0;
    height:100%;
}
.main-menu li > ul::before {
    left:0;
    padding-left:50vw;
    margin-left:-50vw;
    background:#f6f6f6;
}
.main-menu li > ul::after {
    right:0;
    padding-right:50vw;
    margin-right:-50vw;
    background:#f6f6f6;
}
.main-menu .depth-1 .title {
    font-size:18px;
}
.main-menu .depth-1 a {
    width: 160px;
    margin: 30px;
}

.header-wrapper{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}