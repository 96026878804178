/*============ sub page============*/
.page-content {
    padding:3rem 0;
    a{
        color:inherit;
    }
}

.splash{
    position: relative;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-self: center;
    .icon{
        transform: scale(1.2);
        @media screen and (max-width:1450px) {
            transform: scale(1);
        }
    }
    .page-graphic img{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100% !important;
        object-fit: cover;
        z-index: -1;
    }
    .splash-content{
        padding:4rem 2rem;
        grid-gap:3rem;
        color:var(--white);
        align-items: center;
        .content{
            max-width: calc(680px - 2rem);
            margin-left: auto;
            padding-top:3rem;
            padding-bottom:3rem;
            .page-summary{
                font-weight: 400;
            }
        }
        @media screen and (max-width:768px) {
            text-align: center;
            grid-template-columns: 1fr;
            .content{
                padding-top:1rem;
                padding-bottom:1rem;
                
            }
        }
    }
}
.t-page{
    .splash{
        .btn-solid{
            color:var(--pink);
            font-weight: 700;
        }
    }
}
.t-survey{
    .splash{
        .btn-solid{
            color:var(--blue);
            font-weight: 700;
        }
    }
    .step-wrapper{
        .step-list{
            display:flex;
            flex-direction: column;
            .step-item{
                display:grid;
                grid-gap:8rem;
                grid-template-columns: max-content 1fr;
                padding:8rem 0;
                border-bottom:1px solid var(--blue);
                .step-content{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
                .step-number{
                    font-size: clamp(18px, 3.5vw, 24px);
                    font-weight: 700;
                    color:var(--blue);
                    margin-bottom: 0;
                }
                .step-title{
                    font-size: clamp(20px, 4.5vw, 48px);
                    font-weight: 700;
                    color:var(--pink);
                }
                .step-icon{
                    width: 25vw;
                    max-width: 250px;
                }
                @media screen and  (max-width:800px){
                    grid-template-columns: 1fr;
                    grid-gap:2rem;
                    .step-icon{
                        display: flex;
                        justify-content: center;
                        margin: 0 auto;
                    }
                    .step-content{
                        align-items: center;
                        text-align: center;
                    }
                }
            }
        }
    }

}
.t-faq{
    .splash{
        .btn-solid{
            color:var(--pink);
            font-weight: 700; 
        }
    }
    .page-content{
        padding-top:0;
        padding-bottom:0;
    }
    .faq-wrapper{
        padding-top: 0;
        .faq-item{
            border: 1px solid var(--blue);
            .expand-icon{
                position: relative;
                min-width: min(15vw, 120px);
                padding:0 6px;
                color: var(--white);
                font-weight: 800;
                display: flex;
                justify-content: center;
                align-items: center;
                &::before,&::after{
                    position: absolute;
                    content:"|";
                    font-size: 30px;
                    line-height: 30px;
                    display: inline-block;
                }
                &::before{
                    transform: rotate(90deg);
                    transition: transform .3s ease-in-out;
                }
         
                &::after{
                   
                    transform: rotate(90deg);
                }
            }
            .faq-title{
                margin:0;
                padding:2rem 0;
                padding-right: 2rem;
                background-color: var(--blue);
                color: var(--white);
                display: flex;
                width: 100%;
                font-size: clamp(17px, 3vw, 28px);
                font-weight: 400;
                transition: background-color .3s ease-in-out;
            }
            .faq-content{
                transition: max-height .3s ease-in-out;
                overflow: hidden;
                .wrapper{
                    padding:2rem;
                    padding-left:min(15vw, 120px);
                }
            }
            &.fold{
             
                .faq-title{
                    background-color: var(--white);
                    color:var(--black);
                    .expand-icon{
                        color: var(--pink);
                        &::before{
                            transform: rotate(0deg)translateX(-1px);
                        }
                    }
                }
                .faq-content{
                    max-height: 0px!important;
                   
                }
            }
            @media screen and  (max-width:500px){
                .expand-icon{
                    display: none;
                };
                .faq-title{
                    padding:1rem ;
             
                }
                .faq-content{
                    .wrapper{
                        padding:1rem;
                  
                    }
                }
                
            }
        }
    }
}
.t-staff{
    .header-wrapper{
        min-height: 0;
    }

    .staff-block{
        .block-title{
            display: none;
        }
        .staff-card{
            hr, .summary, .btn-outline-blue{
                display: none;
            }
        }
        .staff-highlight{
            grid-gap:clamp(2rem, 4vw, 6rem);
            margin-bottom: 8rem;
            .staff-icon{
                width:100%;
                img{
                    width: 100%;
                    height: 100%!important;
                    object-fit: contain;
                }
            }
            .staff-title{
                font-size: clamp(18px, 3.5vw, 48px);
                font-weight: 700;
                color: var(--pink);
                margin-bottom:1rem;
            }
            .staff-position{
                margin:0;
                color: var(--blue);
                font-size: clamp(17px, 3.5vw, 24px);
                font-weight: 700
            }
            @media screen and (max-width:768px) {
                grid-template-columns: 1fr;
                padding:2rem;
            }
            @media screen and (max-width:500px) {
                padding:1rem;
            }
        }
    }
 
}
