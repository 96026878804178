/*============ splash ============*/
/*  banner */
.banners-home .banner-stage {
    padding-top:33%;
    position:relative;
}
.banners-home .banner {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    display:block;
    background-position:center;
    background-size:cover;
}
.banners-home .banner-navigation {
    display:none;
}