
/** form generic **/
form {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap:.5rem;
    .two-col{
        grid-gap:.75rem;
    }
}
form p {
    position: relative;
    margin: 5px 0;
}
label.float,
input {
    height: 40px;
    line-height: 40px;
    color: var(--pink)
}
label.error {
    display: block;
    font-size: 12px;
    line-height: 1;
}
input,
textarea {
    text-align: left;
    padding-left: 20px;
    font-family: var(--font-primary);
    font-size: 16px;
    padding-top: 15px;
    width: 100%;
    box-sizing: border-box;
    border:1px solid var(--pink);
    color: var(--pink);
}
textarea {
    min-height: 80px;
}
label.float {
    position: absolute;
    left:0;
    color: var(--pink);
    padding-left: 15px;
    max-width: 100%;
    box-sizing: border-box;
    transition: all 1s ease;
    z-index: 1;
}
.js-float-wrap {
    position:relative;
}
.js-float-wrap.focused label.float,
.js-float-wrap.populated label.float {
    left: 5px;
    padding-left: 0;
    line-height: 1;
    font-size: 0.75em;
}
form button {
    font-weight: 700;
    width: 100%;
    cursor: pointer;
    margin-top:.5rem;
    max-width: 150px!important;
}
#form-result{
    text-align: center;
    
}