/*============ footer ============*/
footer .footer-top {
    background-color:var(--blue);
    color:var(--white);
    padding: min(5vw, 8rem);
    padding-top:9rem;
    font-size: clamp(16px, 3.5vw, 20px);
    display:flex;
    font-weight: 700;
    justify-content: space-between;
    @media screen and (max-width:1400px){
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 8rem;
        .footer-graphic{
            margin-left:auto;
        }
    }
}
footer .footer-bottom {
    background-color:var(--black);
    color:var(--white);
}
footer .footer-area{
    display: grid;
    grid-gap:4rem;
    grid-template-columns: max-content max-content;
    grid-template-areas: 
    "logo menu"
    "partner contact";
   
    .footer-logo{
        grid-area: logo;
        margin:auto;
    }
    .footer-partner{
        grid-area: partner;
        max-width: 278px;
    }
    .footer-menu{
        grid-area: menu;
        display: flex;
        align-items: flex-end;
        gap:1.5rem;
        ul{
            display: flex;
            gap:1.5rem;

        }
        ul:first-child li:last-child{
            display: none;
        }
    }
    .footer-contact{
        grid-area: contact;
        display: flex;
        flex-direction: column;

        .item{
            display: grid;
            grid-template-columns: 50px auto;
            grid-gap: 1rem;
            margin-bottom: 1rem;
        }
        .email{
            .label{
                content:url('../assets/email.svg');
                width:20px;
                height: 20px;
            }
        }
        .abn .label{
            color:var(--black);
        
            
        }
    }
    @media screen and (max-width:1200px) {
        grid-template-areas: 
            "logo logo"
            "menu menu"
            "partner contact";
        .footer-contact{
            justify-content: center;
        }
    }
    @media screen and (max-width:768px) {
        grid-template-columns: 1fr;
        grid-template-areas: 
        "logo"
        "menu"
        "partner"
        "contact";
        .footer-menu{
            flex-direction: column;
            align-items: center;
            text-align: center;
            ul{
                flex-direction: column;  
            }
        }
 
    }
}
footer .footer-graphic{
    margin-top: auto;
    max-width: 540px;
    min-width: 200px;
    @media screen and (max-width:768px) {
        margin:0 auto;
        padding:2rem 3rem;
    }
}
footer .extra-menu{
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-left:calc(min(5vw, 8rem) + 340px);
    align-items: center;
    padding-right: 4rem;
    ul{
        list-style: none;
        display:flex;
        gap: 2rem;
        padding:0;
        flex-wrap: wrap;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
    @media screen and (max-width:1400px) {
        padding-left: 0;
        padding:1rem 2rem;
    }
    @media screen and (max-width:768px) {
        flex-direction: column;
        .site-links{
            margin: 1.5rem 0;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        }
    }
}
/** bykeo ***/
.bykeo {
    display: block;
    text-align: center;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
}
.bykeo a {
    color: grey;
    text-decoration:none;
}
.bykeo img {
    vertical-align: middle;
    display: inline-block;
    transition: transform 500ms ease;
}
.bykeo a:hover img{
    transform-origin: center;
    transform: translateX(-200%) rotate(-360deg);
}